<template>
    <div class="form-control my-3">
        <v-card>
            <v-card-title v-if="tableType === 'node'">
                {{ $t('schedule.selectedNodes') }} :
            </v-card-title>
            <v-card-title v-else>
                {{ $t('schedule.selectedZones') }} :
            </v-card-title>
            <v-card-text>
                <slot></slot>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: "SelectedNodes",
    // tableType can be 'zone' or 'node'
    props: {
        tableType: {
            type: String,
            required: true,
            default: 'node'
        }
    }
}
</script>
<style scoped>
.form-control {
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 5px;
    box-shadow: #636363 5px 5px 5px;
    margin: 0 5px 0 0;
    padding: 30px 30px 30px 30px;
}
</style>