<template>
    <div class="px-6 switchCardBg">
        <div class="d-flex justify-space-between mx-6">
            <div class="d-flex-column">
                <div>
                    <strong>{{ $t('schedule.noSwitches') }}</strong>
                </div>
                <div>
                    {{ $t('schedule.noSwitchesText') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BlankSwitchCard"
}
</script>

<style scoped>
.switchCardBg {
    background-color: #f2f2f2;
    border: 1px solid black;
}
.switchCardHeader {
    background-color: #e6e6e6;
}
</style>