<template>
    <div class="canvas-wrapper mt-6">
        <v-img
            class="accent--text align-end"
            fluid-grow
            src="@/assets/sunrise-sunset-times-sunrise-and-sunset-png.jpg"
        ></v-img>
    <canvas ref="canvas" class="canvas-overlay" height="170" width="600"></canvas>

    </div>
</template>

<script>
import {dateConv, timeConv} from '@/services/dateConverter';

export default {
    name: "SunRiseSetImg",
    props: ['sunriseset', 'now'],
    mounted() {
        this.canvas = this.$refs.canvas;
        this.doDraw();
    },
    data() {
        return {
            image: null,
            canvas: null,
            base:{
                total: 0,
                now: 0,
                x: {
                    minutes: 0,
                    pix: 510,
                    minutesPerStep: 0,
                },
                y: {
                    // parabool met top in midden en negatieve r/c
                    minutes: 0,
                    pix: 83,
                    minutesPerStep: 0,
                },
            }
        };
    },
    methods: {
        doDraw(){

            const context = this.canvas.getContext('2d');
            let bg = new Image();
            bg.src= "@/assets/sunrise-sunset-times-sunrise-and-sunset-png.jpg";
            bg.onload = function() {
                context.drawImage(bg, 0, 0);
            };
            let titleText;
            if (this.$i18n.locale === 'en') {
                titleText = "Sunrise Sunset today";
            } else {
                titleText = "Zonsopkomst en -ondergang";
            }
            this.doText(30, 30, titleText);
            this.calcCoords();
            this.doSunriseSunset();
        },
        doDot(x, y){
            const context = this.canvas.getContext('2d');
            context.beginPath();
            context.arc(x,y,10,0, 2 * Math.PI);
            context.fillStyle = "yellow";
            context.stroke();
            context.fill();
            context.fillStyle = "black"; // back to black again
        },
        doSunriseSunset(){
            const canvas = this.$refs.canvas;
            const context = canvas.getContext("2d");
            context.font = "13px Arial";
            context.fillText(this.sunriseset.sunrise, 30, 155);
            context.fillText(this.sunriseset.sunset, 540, 155);
        },
        doText(x, y, textInside){
            const canvas = this.$refs.canvas;
            const context = canvas.getContext("2d");
            context.font = "20px Arial";
            context.fillText(textInside, x, y);
        },
        calcCoords(){

            const timeNow = new Date();
            const [date, month, year] = [timeNow.getDate(), timeNow.getMonth() + 1, timeNow.getFullYear()];
            const dateNow = dateConv(timeNow, 1);
            const sunrise2 = new Date(`${dateNow}T${this.sunriseset.sunrise}:00`);
            const sunset2 = new Date(`${dateNow}T${this.sunriseset.sunset}:00`);
            // the next line is for testing purposes only
            const testNow = new Date(`${dateNow}T17:55:00`);
            let x = 0;
            let y = 0;

            this.base.total = (sunset2.getTime() - sunrise2.getTime()) / (1000 * 60);
            this.base.x.minutesPerStep = this.base.total / (this.base.x.pix / 5);
            this.base.y.minutesPerStep = this.base.total / (this.base.y.pix / 5);

            const difference = (timeNow.getTime() - sunrise2.getTime()) / (60 * 1000);

            const x_calc = (difference / this.base.x.minutesPerStep) * 5;
            x = 45 + x_calc;

            let y_calc = (-1 * x_calc * x_calc / 783.43) + (510 * x_calc / 783.43);
            y = 136 - y_calc;
            this.doDot(x, y);
        },
    },
}
</script>

<style scoped>
.canvas-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    width: 100%;
    height: 100%
}

.canvas-wrapper {
    position: relative;
}
</style>