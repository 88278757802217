<template>
    <div>
        <form class="form-control">
            <div class="form-header">
                <h3>Profile <slot name="profile_id">= Unknown =</slot> for IO<slot name="io_id">= Unknown =</slot></h3>
            </div>
            <div class="switch-list">
                <ul>
                    <li v-for="switch1 in tempSwitches" :key="switch1.switch_id">
                        <span class="d-inline-flex align-center"><switch-display-card :switch1="switch1" /></span>
                        <span class="align-content-center float-right"><v-btn class="mt-5" icon small @click="removeSwitch(switch1.switch_id)"><v-icon color="red">mdi-delete</v-icon></v-btn></span>
                    </li>
                </ul>
            </div>
            <div class="d-flex align-center body-control">
                <label class="label-style" for="inputProfileStart">{{ $t('main.start') }} : </label>
                <input type="text" id="inputProfileStart" :placeholder="startPlaceholder" v-model="profileStart"/>
                <div class="pt-6" :style="$i18n.locale === 'en' ? 'width: 130px': 'width: 150px'">
                    <v-select
                        v-model="profileStartType"
                        full-width
                        :items="inputItems"
                        :label="$t('schedule.switchType')"
                        outlined
                        background-color="white"
                        color="blue"
                        item-color="red"
                        dense
                    ></v-select>
                </div>
                <div class="inputInfoBox">
                    <h6>{{ inputInfoTranslation('start') }}</h6>
                </div>
            </div>
            <div class="d-flex align-center body-control">
                <label class="label-style" for="inputProfileEnd">{{ $t('main.end') }} : </label>
                <input type="text" id="inputProfileEnd" :placeholder="endPlaceholder" v-model="profileEnd" />
                <div class="pt-6" :style="$i18n.locale === 'en' ? 'width: 130px': 'width: 150px'">
                    <v-select
                        v-model="profileEndType"
                        full-width
                        :items="inputItems"
                        :label="$t('schedule.switchType')"
                        outlined
                        background-color="white"
                        color="blue"
                        item-color="red"
                        dense
                    ></v-select>
                </div>
                <div class="inputInfoBox">
                    <h6>{{ inputInfoTranslation('end') }}</h6>
                </div>
            </div>

            <div class="d-flex justify-space-between">
                <v-btn class="ma-3" color="success" :disabled="!validSwitch || tempSwitches.length > 3" @click="addSwitch">
                    {{ $t('main.addSwitch') }}</v-btn>
                <v-btn class="ma-3" color="error" @click="clearSwitches" :disabled="tempSwitches.length === 0">
                    {{ $t('main.clearSwitch') }}</v-btn>
            </div>
            <div class="week-control">
                <v-row class="ma-0">
                    <v-col class="week-border" @click="checkBox('0')">
                        <input id="toggleMonday" v-model="toggleWeek" name="toggleWeek" type="checkbox" value="0"/>
                    </v-col>
                    <v-col class="week-border" @click="checkBox('1')">
                        <input id="toggleTuesday" v-model="toggleWeek" name="toggleWeek" type="checkbox" value="1" />
                    </v-col>
                    <v-col class="week-border" @click="checkBox('2')">
                        <input id="toggleMWednesday" v-model="toggleWeek" name="toggleWeek" type="checkbox" value="2" />
                    </v-col>
                    <v-col class="week-border" @click="checkBox('3')">
                        <input id="toggleThursday" v-model="toggleWeek" name="toggleWeek" type="checkbox" value="3" />
                    </v-col>
                    <v-col class="week-border" @click="checkBox('4')">
                        <input id="toggleFriday" v-model="toggleWeek" name="toggleWeek" type="checkbox" value="4" />
                    </v-col>
                    <v-col class="week-border" @click="checkBox('5')">
                        <input id="toggleSaturday" v-model="toggleWeek" name="toggleWeek" type="checkbox" value="5" />
                    </v-col>
                    <v-col class="week-border" @click="checkBox('6')">
                        <input id="toggleSunday" v-model="toggleWeek" name="toggleWeek" type="checkbox" value="6" />
                    </v-col>
                    <v-col class="week-border" @click="checkBox('ALL')">
                        <input id="toggleWeek" v-model="toggleAll" name="toggleWeek" type="checkbox" value="ALL" />
                    </v-col>
                </v-row>
                <v-row class="ma-0">
                    <v-col class="week-border" @click="checkBox('0')">{{ $t('schedule.weekdays.mon') }}</v-col>
                    <v-col class="week-border" @click="checkBox('1')">{{ $t('schedule.weekdays.tue') }}</v-col>
                    <v-col class="week-border" @click="checkBox('2')">{{ $t('schedule.weekdays.wed') }}</v-col>
                    <v-col class="week-border" @click="checkBox('3')">{{ $t('schedule.weekdays.thu') }}</v-col>
                    <v-col class="week-border" @click="checkBox('4')">{{ $t('schedule.weekdays.fri') }}</v-col>
                    <v-col class="week-border" @click="checkBox('5')">{{ $t('schedule.weekdays.sat') }}</v-col>
                    <v-col class="week-border" @click="checkBox('6')">{{ $t('schedule.weekdays.sun') }}</v-col>
                    <v-col class="week-border" @click="checkBox('ALL')"><strong>{{ $t('schedule.weekdays.all') }}</strong></v-col>
                </v-row>
            </div>
            <div>
                <v-row cols="12" class="d-flex justify-space-between align-center">
                    <v-col class="col-4">
                        <label for="recurringInput">{{ $t('main.recurring') }}</label><input type="radio" name="range" id="recurringInput" v-model="recurring" value="recurring"/>
                        <label for="rangeInput">{{ $t('main.range') }}</label><input type="radio" name="range" id="rangeInput" v-model="recurring" value="range"/>
                    </v-col>
                    <v-col class="col-8" v-if="recurring === 'range'">
                        <label for="inputStartDate"></label>{{ $t('schedule.startDate') }} : <input class="input2" type="text" id="inputStartDate" :placeholder="i18nPlaceholder" v-model="range.startDate"/> -
                        <label for="inputDateEnd">{{ $t('schedule.endDate') }} : </label><input class="input2" type="text" id="inputDateEnd" :placeholder="i18nPlaceholder" v-model="range.endDate" />
                    </v-col>
                </v-row>
                <v-row cols="12">
                    <v-col class="col-4">

                    </v-col>
                    <v-col class="col-8" v-if="recurring === 'range'">
                        <div class="errorMsg" v-if="!validStartDate">
                            {{ $t('schedule.validStartDate') }}
                        </div>
                        <div class="errorMsg" v-if="!validEndDate">
                            {{ $t('schedule.validEndDate') }}
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div>
                <v-btn class="ma-4" color="warning" @click="cancelAction" >{{ $t('main.cancel') }}</v-btn>
                <v-btn class="ma-4" color="success" :disabled="!validProfile" @click="addOrSaveProfile(ioInputType)" v-if="ioInputType === 'new'">{{ $t('main.addProfile') }}</v-btn>
                <v-btn class="ma-4" color="success" :disabled="!validProfile" @click="addOrSaveProfile(ioInputType)" v-if="ioInputType === 'edit'">{{ $t('main.saveProfile')}}</v-btn>
            </div>
        </form>
        <ConfirmDialog ref="confirmDialogue" ></ConfirmDialog>
    </div>
</template>

<script>
import SwitchDisplayCard from "@/components/Schedule/SwitchDisplayCard";
import {offsetConv, timeStrConv} from "@/services/dateConverter";
import {specialDateConv} from "@/services/dateConverter";
import ConfirmDialog from "@/components/Standard/ConfirmDialog";

const reTime = /(^(2[0-3]|[01]?[0-9])([:.]?)([0-5]?[0-9])$)/
const reOffset = /^([+-]?\d{0,4}[,.]?\d{0,2})([HhMm]?)$/

const reDateEn = /(^(1[0-2]|0?[1-9])[/.-](3[01]|[12][0-9]|0?[1-9])$)/
const reDateNl = /(^(3[01]|[12][0-9]|0?[1-9])[/.-](1[0-2]|0?[1-9])$)/

export default {
    name: "ProfileInputCard",
    components: {
        SwitchDisplayCard,
        ConfirmDialog
    },
    emits: ['save-profile'],
    props: {
        io_data: {
            type: Object,
            required: false,
            default: null
        },
        io_id: {
            type: Number,
            required: false,
            default: -1
        }
    },
    created() {
        if (this.io_data) {
            this.ioInputType = 'edit';
            this.populateData();
        } else {
            this.ioInputType = 'new';
        }
    },
    data() {
        return {
            tempSwitches: [],
            startTime: '',
            endTime: '',
            profileStart: '',
            profileEnd: '',
            profileStartType: 'absolute',
            profileEndType: 'absolute',
            // inputInfo: {
            //     'absolute': this.$t('tooltip.absoluteInfo'),
            //     'sunrise': this.$t('tooltip.sunriseInfo'),
            //     'sunset': this.$t('tooltip.sunsetInfo'),
            // },
            inputItems: [
                {
                    id: 1,
                    text: this.$t('schedule.absolute'),
                    value: 'absolute'
                },
                {
                    id: 2,
                    text: this.$t('schedule.sunrise'),
                    value: 'sunrise'
                },
                {
                    id: 3,
                    text: this.$t('schedule.sunset'),
                    value: 'sunset'
                }
            ],
            toggleWeek: [],
            toggleAll: false,
            recurring: 'recurring',
            range: {
                startDate: '',
                endDate: ''
            },
            ioInputType: 'new',
            switchCount: 0
        };
    },
    computed: {
        i18nPlaceholder(){
            let result;
            if (this.$i18n.locale === 'en') {
                result = "MM-DD";
            } else result = "DD-MM";
            return result;
        },
        validSwitch(){
            let valid = true;
            return valid && (this.startValid && this.endValid);
        },
        validProfile(){
            let valid = true;
            valid = valid && (this.toggleWeek.length > 0);
            if (this.recurring === 'range') {
                valid = valid && (this.validStartDate && this.validEndDate);
            }
            return valid && (this.tempSwitches.length > 0);
        },
        validStartDate() {
            let isValid = false;
            if (this.$i18n.locale === 'en'){
                isValid = reDateEn.test(this.range.startDate);
            } else {
                isValid = reDateNl.test(this.range.startDate);
            }
            return isValid;
        },
        validEndDate() {
            let isValid = false;
            if (this.$i18n.locale === 'en'){
                isValid = reDateEn.test(this.range.endDate);
            } else {
                isValid = reDateNl.test(this.range.endDate);
            }
            return isValid;
        },
        startValid() {
            let valid = false;
            if (this.profileStartType === 'absolute'){
                valid = reTime.test(this.profileStart) && this.profileStart.length > 2;
            } else {
                valid = reOffset.test(this.profileStart) && this.profileStart.length > 0;
            }
            return valid;
        },
        endValid() {
            let valid = false;
            if (this.profileEndType === 'absolute'){
                valid = reTime.test(this.profileEnd) && this.profileEnd.length > 2;
            } else {
                valid = reOffset.test(this.profileEnd) && this.profileEnd.length > 0;
            }
            return valid;
        },
        startPlaceholder() {
            let ph;
            if (this.profileStartType === 'absolute'){
                ph = 'schedule.startPlaceholderAbs';
            }
            return this.$t(ph);
        },
        endPlaceholder() {
            let ph;
            if (this.profileEndType === 'absolute'){
                ph = 'schedule.endPlaceholderAbs';
            }
            return this.$t(ph);
        },
    },
    methods: {
        cancelAction(){
            this.$emit('save-profile', 'cancel', null);
            this.clearProfile();
        },
        populateData() {
            let id = 0;
            for (let switch1 of this.io_data.switches){
                const switch2 = {
                    switch_id: id++,
                    off: switch1.off,
                    on: switch1.on,
                }
                this.tempSwitches.push(switch2);
            }
            this.toggleWeek = [...this.convertWeekNum(this.io_data.days)];
            if (this.io_data.startDate) {
                this.recurring = 'range';
                this.range.startDate = this.io_data.startDate;
                this.range.endDate = this.io_data.endDate;
            }
        },
        addSwitch(){
            let switchOn;
            let switchOff;
            const switchId = this.tempSwitches.length;
            if (this.profileStartType === 'absolute'){
                switchOn = {
                    kind: 'Absolute',
                    time: timeStrConv(this.profileStart, 0)
                };
            } else {
                switchOn = {
                    kind: this.capitalizeString(this.profileStartType),
                    offset: offsetConv(this.profileStart)
                };
            }
            if (this.profileEndType === 'absolute') {
                switchOff = {
                    kind: 'Absolute',
                    time: timeStrConv(this.profileEnd)
                };
            } else {
                switchOff = {
                    kind: this.capitalizeString(this.profileEndType),
                    offset: offsetConv(this.profileEnd)
                };
            }
            const thisSwitch = {
                switch_id: switchId,
                on: {
                    ...switchOn,
                },
                off: {
                    ...switchOff,
                },
            };
            this.tempSwitches.push(thisSwitch);
            this.switchCount++;
            this.profileStart = '';
            this.profileEnd = '';
            this.profileStartType = 'absolute';
            this.profileEndType = 'absolute';
        },
        addOrSaveProfile(inpType) {
            // type is 'new' or 'edit'
            // 'new' -> profile to be created
            // 'edit' -> profile to be overwritten

            let localeStartDate, localeEndDate;
            if (this.$i18n.locale === 'nl') {
                localeStartDate = this.range.startDate ? specialDateConv(this.range.startDate) : '';
                localeEndDate = this.range.endDate ? specialDateConv(this.range.endDate) : '';
            } else {
                localeStartDate = this.range.startDate ? this.range.startDate : '';
                localeEndDate = this.range.endDate ? this.range.endDate : '';
            }
            const switches = {
                profile_id: this.io_data ? this.io_data.profile_id : -1,
                io_id: this.io_id,
                switches: [...this.tempSwitches],
                days: this.convertWeekDay(this.toggleWeek),
                startDate: localeStartDate,
                endDate: localeEndDate
            }
            this.$emit('save-profile' ,inpType , switches);
            this.clearProfile();
        },
        clearSwitches() {
            this.tempSwitches = [];
            this.profileStart = '';
            this.profileEnd = '';
            this.profileStartType = 'absolute';
            this.profileEndType = 'absolute';
        },
        clearProfile() {
            this.clearSwitches();
            this.toggleWeek = [];
            this.toggleAll = false;
            this.range = { startDate: '', endDate: ''};
            this.recurring = 'recurring';
            this.ioInputType = 'new';
        },
        async removeSwitch(switchId) {
            const ok = await this.$refs.confirmDialogue.show({
                title: this.$t('main.deleteSwitch'),
                message: this.$t('main.deleteSwitchMessage'),
                okButton: this.$t('main.remove'),
            });
            if (ok){
                const switchIndex = this.tempSwitches.findIndex(elem => elem.switch_id === switchId);
                this.tempSwitches.splice(switchIndex, 1);
            }

        },
        convertWeekDay(arrayOfNumbers) {
            let arrayOfDays = [];
            arrayOfNumbers.forEach((num) => {
                switch(num) {
                    case '0':
                        arrayOfDays.push('mon');
                        break;
                    case '1':
                        arrayOfDays.push('tue');
                        break;
                    case '2':
                        arrayOfDays.push('wed');
                        break;
                    case '3':
                        arrayOfDays.push('thu');
                        break;
                    case '4':
                        arrayOfDays.push('fri');
                        break;
                    case '5':
                        arrayOfDays.push('sat');
                        break;
                    case '6':
                        arrayOfDays.push('sun');
                        break;
                }
            });
            return arrayOfDays;
        },
        convertWeekNum(arrayOfDays) {
            let arrayOfNums = [];
            arrayOfDays.forEach((day) => {
                switch(day) {
                    case 'mon':
                        arrayOfNums.push('0');
                        break;
                    case 'tue':
                        arrayOfNums.push('1');
                        break;
                    case 'wed':
                        arrayOfNums.push('2');
                        break;
                    case 'thu':
                        arrayOfNums.push('3');
                        break;
                    case 'fri':
                        arrayOfNums.push('4');
                        break;
                    case 'sat':
                        arrayOfNums.push('5');
                        break;
                    case 'sun':
                        arrayOfNums.push('6');
                        break;
                }
            });
            return arrayOfNums;
        },
        checkBox(event) {
            if (event === "ALL"){
                this.toggleAll = !this.toggleAll;
            } else {
                const idx = this.toggleWeek.indexOf(event);
                if (idx !== -1) this.toggleWeek.splice(idx, 1);
                else this.toggleWeek.push(event);
            }
        },
        capitalizeString(string1){
            return string1.charAt(0).toUpperCase() + string1.substring(1, string1.length);
        },
        inputInfoTranslation(type){
            let inputInf;
            if (type === 'start') {
                inputInf = this.profileStartType;
            } else {
                inputInf = this.profileEndType;
            }
            switch (inputInf) {
                case 'absolute':
                    return this.$t('tooltip.absoluteInfo');
                case 'sunrise':
                    return this.$t('tooltip.sunriseInfo');
                case 'sunset':
                    return this.$t('tooltip.sunsetInfo');
                default:
                    return '';
            }
        }
    },
    watch: {
        toggleAll(value){
            if (value) {
                this.toggleWeek = ["1", "2", "3", "4", "5", "6", "0"];
            } else {
                this.toggleWeek = [];
            }
        },
        profileStartType(newV, oldV) {
            if(oldV !== newV){
                if (newV !== 'absolute')
                {
                    if(!this.profileStart)
                    this.profileStart = '0';
                } else {
                    this.profileStart = '';
                }
            }
        },
        profileEndType(newV, oldV) {
            if(oldV !== newV){
                if (newV !== 'absolute')
                {
                    if(!this.profileEnd)
                    this.profileEnd = '0';
                } else {
                    this.profileEnd = '';
                }
            }
        }
    },
}
</script>

<style scoped>
.errorMsg {
    color: red;
    border-color: red;
}
.form-header {
    background-color: #D3D3D3;
    padding: 15px 15px 15px 15px;
}
.form-control {
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 5px;
    box-shadow: #636363 5px 5px 5px;
    margin: 0 5px 0 0;
    padding: 30px 30px 30px 30px;
}
input {
    border: 1px grey solid;
    padding: 5px 10px 5px 10px;
    margin: 20px;
}
.input2 {
    border: 1px grey solid;
    padding: 0 5px 0 5px;
    margin: 0;
    width: 80px;
}
.body-control {
    max-width: 100%;
    margin: 10px;
}
.week-control {
    margin: 0 5px;
    text-align: center;
}
.week-border {
    border: 1px solid #e3e3e3;
    border-collapse: collapse;
    cursor: pointer;
}
.inputInfoBox {
    width: 200px;
    border: red solid 1px;
    margin: 0 0 0 15px;
    padding: 2px;
}
.label-style{
    width: 60px;
    display: block;
}
</style>