<template>
    <div class="form-control">
        <v-tabs
            color="accent"
            fixed-tabs
            v-model="tab"
            @change="emitIoProfile"
        >
            <v-tab v-for="io in chosenSchedule.schedules" :key="io.io_id">
                {{ io.io_id }} ({{ $t(`module.${io.io_id}`)}})
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item
                v-for="(io2, index) in chosenSchedule.schedules" :key="io2.io_id"
            >
                <div>
                    <v-tabs
                        v-model="tabProfile"
                        vertical
                        color="accent"
                        grow
                        v-if="tab === index"
                        @change="emitIoProfile"
                    >
                        <v-tab
                            v-for="prlen in io2.profiles.length"
                            :key="`${io2.io_id}${prlen - 1}${nowCalculated}`"
                        >{{ $t('main.profile') }} {{ prlen }}</v-tab>
                        <v-tabs-items v-model="tabProfile">
                            <v-tab-item
                                v-for="(profile2, index2) in io2.profiles"
                                :key="`${io2.io_id}${index2}${nowCalculated}`">
                                <switch-profile-card :profile="profile2" />
                            </v-tab-item>
                        </v-tabs-items>

                        <v-tab v-if="io2.profiles.length === 0">
                            {{ $t('main.profile') }} 1
                        </v-tab>
                        <v-tabs-items v-model="tabProfile"  v-if="io2.profiles.length === 0">
                            <v-tab-item>
                                <blank-switch-card />
                            </v-tab-item>
                        </v-tabs-items>

                    </v-tabs>
                </div>
            </v-tab-item>

        </v-tabs-items>

    </div>
</template>

<script>
import SwitchProfileCard from "@/components/Schedule/SwitchProfileCard.vue";
import BlankSwitchCard from "./BlankSwitchCard";

export default {
    name: "ScheduleProfileCard",
    props: ['chosenSchedule'],
    components: {
        SwitchProfileCard,
        BlankSwitchCard,
    },
    emits: ['sendProfile'],
    data() {
        return {
            tab: 0,
            tabProfile: 0,
        };
    },
    computed: {
        nowCalculated() {
            const nowDate = new Date();
            return nowDate.toISOString();
        },
        pathLink() {
            return this.$route.fullPath + `&io=${this.tab}&profile=${this.tabProfile}`;
        },
    },
    methods: {
        emitIoProfile (){
            this.$emit('ioProfile', {currentIo: this.tab, currentProfile: this.tabProfile})
        }
    }
}
</script>

<style scoped>
.mycard {
    background-color: #bdbdbd;
    color: black;
}
.card-border {
    border: 1px solid gray;
}
.form-header {
    background-color: #D3D3D3;
    padding: 15px 15px 15px 15px;
}
.form-control {
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 5px;
    box-shadow: #636363 5px 5px 5px;
    margin: 0 5px 0 0;
    padding: 30px 30px 30px 30px;
}
</style>