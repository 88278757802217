<template>
    <div>
        <v-dialog
            v-model="nodesDialog"
            width="500"
            v-if="tableType === 'zone' && nodesDialog"
        >
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    {{ $t('menu.buttons.nodes') }}
                </v-card-title>

                <v-card-text>
                    All stations in zone {{ tableItems[dialogIndex].name }} ({{ tableItems[dialogIndex].city }})
                </v-card-text>

                <ul class="ma-2">
                    <li v-for="node in tableItems[dialogIndex].nodes" :key="node">{{ node }}</li>
                </ul>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="accent"
                        text
                        @click="nodesDialog = false"
                    >
                        {{ $t('main.ok') }}
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
        <v-data-table
            v-model="selectedRows"
            show-select
            :headers="tableHeaders"
            :items="tableItems"
            item-key="id"
            class="elevation-1"
            @click:row.self="clickRow"
            @item-selected="selectRow"
            @toggle-select-all="toggleAll"
            style="cursor: pointer"
            :search="tableSearch"
            :locale="$i18n.locale"
            :footer-props="{
                itemsPerPageText: $t('main.rowsPerPage'),
                pageText: '{0} - {1} ' + $t('main.of') + ' {2}'
            }"
            :no-data-text="$t('main.noDataFound')"
            :no-results-text="$t('main.noSearchResults')"
            ref="itemTable"
        >
            <template #top>
                <v-toolbar style="cursor: default">
                    <v-toolbar-title class="d-flex flex-row align-center">
                        <div>
                            <h2 class="px-4"><slot name="tableHeader"></slot></h2>
                        </div>
                    </v-toolbar-title>
                    <v-spacer />
                    <v-text-field
                        v-model="tableSearch"
                        append-icon="mdi-magnify"
                        :label="$t('main.search')"
                        single-line
                        clearable
                        dense
                        outlined
                        color="accent"
                        style="width: 100px;"
                        ref="scheduleSearch"
                        hide-details
                        solo
                    ></v-text-field>
                </v-toolbar>
            </template>
            <template #item.online="{ item }">
                <div v-if="item.online === true">
                    <v-icon color="green">mdi-check-circle</v-icon>
                </div>
                <div v-else-if="item.online === false">
                    <v-icon color="red">mdi-close-circle</v-icon>
                </div>
                <div v-else>
                    <v-icon>mdi-minus</v-icon>
                </div>
            </template>
            <template #item.schedule.name="{ item }">
                <div v-if="item.schedule.name !== ''">
                    {{ item.schedule.schedule_id }}: {{ item.schedule.name }}
                </div>
                <div v-else>
                    <v-icon>mdi-minus</v-icon>
                </div>
            </template>
            <template #item.nodes="{ item, index }">
                <div>
                    {{ nodesList(item) }} <v-icon class="ml-2" color="warning" v-if="item.nodes.length > 1" @click.stop="clickDialog(index)">mdi-dots-horizontal-circle-outline</v-icon>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    name: "AssignDataTable",
    data() {
        return {
            tableSearch: '',
            selectedRows: [],
            nodesDialog: false,
            dialogIndex: -1
        };
    },
    beforeDestroy() {
        this.selectedRows = [];
    },
    props: {
        tableHeaders: {
            type: Array,
            required: true
        },
        tableItems: {
            type: Array,
            required: true
        }
    },
    methods: {
        selectRow(item, row) {
            this.$nextTick(() => {
                this.$emit('getClick', this.selectedList);
            })
        },
        clickRow(item, row) {
            if (row.isSelected) {
                row.select(false);
            } else {
                row.select(true);
            }
            this.$emit('getClick', this.selectedList);
        },
        toggleAll(items){
            // Attention ! items is an object wit 2 attributes : items and value.
            // if value is true, all items are selected. Otherwise none are selected.
            const selectedList = items.value === true ? items['items'].map((elm) => elm.id) : [];
            this.$nextTick(() => {
                this.$emit('getClick', selectedList);
            });
        },
        nodesList(item){
            if (this.tableType === "zone") {
                if (item.nodes.length === 0) return "-";
                else if (item.nodes.length === 1) {
                    return `${item.nodes[0]}`;
                }
                else {
                    return `${item.nodes[0]} ...`;
                }
            }
            return "Nothing"
        },
        clickDialog(index) {
            this.nodesDialog = true;
            this.dialogIndex = index;
        }
    },
    computed: {
        selectedList() {
            return this.selectedRows.map((el) => el.id);
        },
        tableType(){
            // returns "zone" if the table is used for zones and "node" when used for nodes
            return this.tableHeaders[0].text === "Name" ? "zone" : "node";
        }
    }
}
</script>
