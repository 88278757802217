<template>
    <div class="box-shadow">
        <v-card :width="panelWidth" class="pa-4 mb-3 overflow-y-auto form-control" color="white">
            <v-card-title >
                 {{ $t('schedule.scheduleInfo') }} {{ chosenSchedule.id }} {{ $t('schedule.version') }} {{ chosenSchedule.version }}
            </v-card-title>
            <div class="d-flex flex-column">
                <div>
                    <div class="d-inline-flex mx-5"><h4>{{ $t('main.name') }}:&nbsp;&nbsp;</h4><div>{{ chosenSchedule.name }}</div></div>
                    <div class="d-inline-flex float-right mx-5"><h4>UID:&nbsp;&nbsp;</h4><div>{{ chosenSchedule.uid }}</div></div>
                </div>
                <div>
                    <div class="d-inline-flex float-right mx-5"><h4>{{ $t('schedule.published') }}:&nbsp;&nbsp;</h4><div>{{ chosenSchedule.published ? $t('main.YES') : $t('main.NO') }}</div></div>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
export default {
    name: "ScheduleProfileHeader",
    props: ['chosenSchedule'],
    computed: {
        panelWidth() {
            const vtf = this.$vuetify.breakpoint.name;
            switch(vtf) {
                case 'xl':
                    return '900';
                case 'lg':
                    return '700';
                default:
                    return '500';
            }
        },
    }
}
</script>

<style scoped>
.form-control {
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 5px;
    box-shadow: #636363 5px 5px 5px;
    padding: 30px 30px 30px 30px;
}
.box-shadow {
    box-shadow: #636363 5px 5px 5px;
    border-radius: 5px;
}
</style>