<template>
    <div class="px-6 switchCardBg">
        <div class="d-flex justify-space-between mx-6">
            <div>
                <strong>{{ $t('schedule.days') }}</strong>&nbsp;&nbsp;:&nbsp;&nbsp;{{ !scheduleEvent ? arraytostr(profile.days) : profile['display'].days }}
            </div>
        </div>
        <div class="d-inline-flex mx-6" v-if="switchNumber !== -1 && scheduleEvent">
            <!-- These are shown in the calendar overview when user clicks on an event. -->
            <div>
                <div class="mb-3 switchCardHeader"><h3>{{ $t('main.switch') }} {{ switchNumber + 1 }}</h3></div>
                <div class="d-flex flex-column">
                    <div class="d-flex flex-row">
                        <div class="switchTitleText"><strong>{{ $t('main.ON') }}:</strong></div><div>{{ profile['display'].onStr }}</div>
                    </div>
                    <div class="d-flex flex-row">
                        <div class="switchTitleText"><strong>{{ $t('main.OFF') }}:</strong></div><div>{{ profile['display'].offStr }}</div>
                    </div>
                </div>

                <div v-if="profile['display'].startDate">
                    <hr>
                    <br>
                    <span><strong>{{ $t('schedule.startDate') }}</strong> : {{ formatDate(profile['display'].startDate) }}</span><br>
                    <span><strong>{{ $t('schedule.endDate') }}</strong> : {{ formatDate(profile['display'].endDate) }}</span>
                </div>
            </div>
        </div>
        <div class="d-inline-flex mx-6" v-for="(switch1, index) in profile.switches" :key="`${index}_${switch1.io_id}`" v-else>
            <!-- These are shown in SchedulesInfo and NewSchedule profile views.. -->
            <div>
                <div class="mb-3 switchCardHeader"><h3>{{ $t('main.switch') }} {{ index + 1 }}</h3></div>
                <div class="d-flex flex-column">
                    <div class="d-flex flex-row">
                        <div class="switchTitleText"><strong>{{ $t('main.ON') }}:</strong></div><div>{{ switch1.on.kind === 'Absolute' ? switch1.on.time.substr(0,5) : formatOffset(switch1.on.offset, switch1.on.kind) }}</div>
                    </div>
                    <div class="d-flex flex-row">
                        <div class="switchTitleText"><strong>{{ $t('main.OFF') }}:</strong></div><div>{{ switch1.off.kind === 'Absolute' ? switch1.off.time.substr(0,5) : formatOffset(switch1.off.offset, switch1.off.kind) }}</div>
                    </div>
                </div>
                <div v-if="profile.startDate">
                    <hr>
                    <div class="d-flex flex-column">
                        <div class="d-flex flex-row">
                            <div class="switchTitleText2"><strong>{{ $t('schedule.startDate') }}:</strong></div><div>{{ formatDate(profile.startDate) }}</div>
                        </div>
                        <div class="d-flex flex-row">
                            <div class="switchTitleText2"><strong>{{ $t('schedule.endDate') }}:</strong></div><div>{{ formatDate(profile.endDate) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { arrayToString } from "@/services/mainHelpers";
import {specialDateConv} from "@/services/dateConverter";

export default {
    name: "SwitchProfileCard",
    props: {
        profile: {
            type: Object,
            required: true,
        },
        switchNumber: {
            type: Number,
            required: false,
            default: -1
        },
        // If scheduleEvent is true. then a schedule event is sent to 'profile' in stead of a profile.
        // so use a different type of data reading.
        scheduleEvent: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        arraytostr(days) {
            let tempDays = [];
            days.forEach((day) => {
                tempDays.push(this.$t(`schedule.weekdays.${day}`));
            });
            return arrayToString(tempDays);
        },
        kind(kindStr){
            const kind = ["Absolute", "Sunrise", "Sunset"]
            const retValue = this.$i18n.locale === 'en' ? ["Absolute", "Sunrise", "Sunset"] : ["Absoluut", "Zonsopkomst", "Zonsondergang"]
            for(let i = 0; i < kind.length; i++){
                if (kindStr === kind[i]) return retValue[i];
            }
            return null;
        },
        formatOffset(value, kind) {
            if (kind !== 'Absolute') {
                if (value === 0) {
                    return `${this.kind(kind)}`;
                }
                const sign = value < 0 ? '-' : '+';
                return `${this.kind(kind)} ${sign} ${Math.abs(value)}s`;
            }
        },
        formatDate(date) {
            let result;
            if (this.$i18n.locale === 'en') {
                result = date;
            } else {
                result = specialDateConv(date);
            }
            return result;
        }
    }
}
</script>

<style scoped>
.switchCardBg {
    background-color: #f2f2f2;
    border: 1px solid black;
}
.switchCardHeader {
    background-color: #e6e6e6;
}
.switchTitleText {
    width: 40px;
}
.switchTitleText2 {
    width: 90px;
}
</style>