<template>
    <div v-if="loggedin">
        <v-row class="ma-4" cols="12">
            <v-col class="col-5">
                <v-sheet height="64" width="100%">
                    <v-toolbar width="100%" floating flat>

                            <v-btn
                                class="mr-xl-10 mr-lg-6 mr-md-2"
                                color="grey darken-2"
                                outlined
                                @click="calcToday"
                            >{{ $t('schedule.today') }}</v-btn>
                            <v-btn
                                color="grey darken-2"
                                class="mx-xl-10 mx-lg-6 mx-md-2"
                                fab
                                small
                                text
                                @click="prev"
                            >
                                <v-icon small>mdi-chevron-left</v-icon>
                            </v-btn>
                        <div class="toolbar-title-width">
                            <v-toolbar-title
                                class="mx-lg-6 mx-2"
                            >
                                {{ titleCal }}
                            </v-toolbar-title>
                        </div>
                            <v-btn
                                color="grey darken-2"
                                class="mx-xl-10 mx-lg-6 mx-md-2"
                                fab
                                small
                                text
                                @click="next"
                            >
                                <v-icon small>mdi-chevron-right</v-icon>
                            </v-btn>
                    </v-toolbar>
                </v-sheet>
                <v-sheet height="400" width="100%">
                    <v-calendar
                        ref="calendar"
                        v-model="today"
                        :now="today"
                        :weekdays="weekdays"
                        color="accent"
                        show-week
                        @click:date="showSunriseset"
                        :locale="$i18n.locale"
                    >
                        <template #day="{ date }">
                            <v-row class="fill-height">
                                <template v-if="tracked[date]">
                                    <v-sheet
                                        :color="colors[0]"
                                        height="10%"
                                        width="100%"
                                    ></v-sheet>
                                </template>
                            </v-row>
                        </template>
                    </v-calendar>
                </v-sheet>
                <sun-rise-set-img :now="today" :sunriseset="suncalc" :title="time"/>
            </v-col>
            <v-col class="col-7">
                <router-view v-if="$route.name === 'schedOverview'" :today2.sync="today" :gpsLocation="location" />
                <router-view v-if="$route.name === 'schedDusk'" :pickedDate="today" :gpsLocation="location" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {dateConv, getWeekNumber, timeConv} from "@/services/dateConverter";
import SunCalc from "suncalc";
import SunRiseSetImg from "@/components/Schedule/SunRiseSetImg";

export default {
    name: "Calendar",
    components: {
        SunRiseSetImg,
    },
    created() {
        this.loggedin = this.isAuthenticated;
        if (this.isAuthenticated) {
            this.calcToday();
        }
    },
    computed: {
        weekCal(){
            return getWeekNumber(new Date(this.today));
        },
        todayCal(){
            return this.today;
        },
        titleCal() {
            const day = new Date(this.today);
            const options = { month: 'long' };
            let locale;
            if (this.$i18n.locale === 'en'){
                locale = 'en-US'
            } else {
                locale = 'nl-NL';
            }
            const month = new Intl.DateTimeFormat(locale, options).format(day);
            const monthPretty = month.charAt(0).toUpperCase() + month.slice(1);

            return monthPretty + " " + day.getFullYear();
        },
        getSunrise(){
            return this.suncalc.sunrise;
        },
        getSunset(){
            return this.suncalc.sunset;
        },
        calendarWidth() {
            const width = this.$vuetify.breakpoint.width;
            const vtf = this.$vuetify.breakpoint.name;
            return (width + 150) / 5.4;
        },
        calendarHeight() {
            return null;
        }
    },
    data() {
        return {
            loggedin: false,
            errored: false,
            today: '',
            time: '',
            weekNr: '',
            month: '',
            tracked: [],
            colors: [ '#fb8c00', '#CECECE', '#BE2830'],
            category: ['AAN', 'SPEC1', 'SPEC2'],
            weekdays: [1, 2, 3, 4, 5, 6, 0],
            weekInfo: null,
            nodesList: [],
            suncalc: {
                sunrise: '0:00',
                sunset: '0:00',
                toggle: false,
            },
            location: {
                lat: 51.4716833,
                lng: 5.6705665,
                type: 'fixed'
            },
            calendarTitle: '',
        };
    },
    methods: {
        ...mapGetters({'isAuthenticated': 'auth/isAuthenticated'}),
        calcToday() {
            const now = new Date();
            this.time = timeConv(now);
            this.today = dateConv(now, 1);
            this.weekNr = getWeekNumber(new Date(this.today));
            const times = SunCalc.getTimes(now, this.location.lat, this.location.lng, 0);
            this.suncalc.sunrise = timeConv(times.sunrise);
            this.suncalc.sunset = timeConv(times.sunset);
            // this.calcTracked();
        },
        prev() {
            this.$refs.calendar.prev();
        },
        next() {
            this.$refs.calendar.next();
        },
        showSunriseset() {
            this.suncalc.toggle = !this.suncalc.toggle;
        },
        calcTracked(){
            this.tracked = [];
            const theEvents = [...this.$store.getters['sched/getEvents']];
            theEvents.forEach((day) => {
                this.tracked[day.date] = [50, 0, 0];
            });
        },
    },
    mounted() {
        this.$refs.calendar.checkChange();
        this.calendarTitle = this.$refs.calendar.title;
        this.ready = true;
    },
}
</script>

<style scoped>
.toolbar-title-width {
    width: 16rem;
    padding: 16px;
}
</style>