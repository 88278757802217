<template>
    <div>
        <div class="ma-12 main-block">
            <v-row cols="12">
                <v-col class="col-6">
                    <div class="form-control">
                        <div class="body-control">
                            <div class="ScheduleView">
                                <v-card-title class="form-header">{{ scheduleName }}</v-card-title>
                                <div class="d-flex justify-space-between body-control header-top py-4">
                                    <span>{{ $t('menu.buttons.schedule') }} UID : <strong>{{ this.newScheduleObject.uid }}</strong></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span>IO : {{ this.ioTab }}<strong></strong></span>
                                    <span>{{ $t('schedule.published') }} : <v-icon :color="isPublished ? 'green' : 'red'">
                                    {{ isPublished ? 'mdi-check' : 'mdi-close' }}</v-icon>
                                    </span>
                                </div>
                                <div class="d-flex body-control header-bottom pb-4">
                                    <span>ID: <strong>{{ this.newScheduleObject.id }}</strong></span>
                                    <span class="ml-4">version:<strong>{{ this.newScheduleObject.version }}</strong></span>
                                </div>
                            </div>
                            <form class="ScheduleInputForm">
                                <div>
                                    <label for="inputScheduleName">{{ $t('schedule.scheduleName') }} : </label>
                                    <input style="width: 400px" type="text" id="inputScheduleName" v-model="scheduleName" :placeholder="$t('schedule.enterScheduleName')" />
                                </div>
                                <div>
                                    <v-tabs
                                        color="accent"
                                        v-model="ioTab"
                                        fixed-tabs>
                                        <v-tab href="#1">IO 1 (out.1)</v-tab>
                                        <v-tab href="#2">IO 2 (out.2)</v-tab>
                                        <v-tab href="#3">IO 3 (out.3)</v-tab>
                                        <v-tabs-items
                                            v-model="ioTab"
                                            v-for="i in 3"
                                            :key="i"
                                            :value="i"
                                        >
                                            <v-card flat>
                                                <ul style="list-style-type: none" v-if="newScheduleObject.schedules[ioTab - 1].profiles.length > 0">
                                                    <li v-for="(profile, index) in newScheduleObject.schedules[ioTab - 1].profiles"
                                                        :key="`${index}_${profile.io_id}_${profile.switches.length}_${profile.days.length}`"
                                                    >
                                                        <div class="d-flex justify-space-between">
                                                            <div>
                                                                <v-icon
                                                                    class="mx-4"
                                                                    color="blue"
                                                                    @click="profileEdit(index)"
                                                                    :title="$t('tooltip.pencil')"
                                                                >
                                                                    mdi-pencil
                                                                </v-icon>
                                                                <v-icon
                                                                    class="mx-4"
                                                                    color="red"
                                                                    @click="profileDelete(index)"
                                                                    :title="$t('tooltip.trashcan')"
                                                                >
                                                                    mdi-delete
                                                                </v-icon>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <switch-profile-card :profile="profile" @save-profile="saveProfile"/>
                                                        </div>

                                                    </li>
                                                </ul>

                                                <div v-else>
                                                    {{ $t('errors.noProfilesYet') }}
                                                </div>
                                                <div class="center">
                                                    <v-tooltip bottom>
                                                        <template #activator="{on, attrs}">
                                                            <v-icon large color="blue" class="ma-4"
                                                                    v-on="on"
                                                                    v-bind="attrs"
                                                                    @click="profileInputType = 'new'"
                                                            >
                                                                mdi-plus
                                                            </v-icon>
                                                        </template>
                                                        <span>{{ $t('tooltip.addNewProfile') }}</span>
                                                    </v-tooltip>
                                                </div>
                                            </v-card>
                                        </v-tabs-items>
                                    </v-tabs>
                                </div>
                            </form>

                        </div>
                        <div class="body-control">
                            <!-- CANCEL BUTTON -->
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mx-2"
                                        v-on="on"
                                        color="warning"
                                        @click="cancelChanges"
                                    >{{ $t('main.cancel') }}</v-btn>
                                </template>
                                <span v-html="$t('tooltip.cancelSchedule')"></span>
                            </v-tooltip>

                            <!-- SAVE BUTTON -->
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        <v-btn
                                            class="mx-2"
                                            :disabled="!isValid1 || !hasChanged"
                                            @click="makeJsonFile"
                                            color="success"
                                        >{{ $t('schedule.saveScheduleToApi') }}</v-btn>
                                    </span>
                                </template>
                                <span v-if="!isValid1 || !hasChanged">{{ $t('tooltip.noChangesToSave') }}</span>
                                <span v-else>{{ $t('tooltip.saveSchedule') }}</span>
                            </v-tooltip>

                            <!-- PUBLISH BUTTON -->
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mx-2 float-right"
                                        v-on="on"
                                        color="success"
                                        @click="changePublished"
                                        :disabled="isPublished && !hasChanged"
                                    >{{ $t('main.publish') }}</v-btn>
                                </template>
                                <span v-html="$t('tooltip.publishSchedule')"></span>
                            </v-tooltip>

                        </div>
                        <div v-if="hasChanged" class="errorBox ma-4 pa-4">
                            {{ $t('errors.unsavedChanges') }}
                        </div>
                    </div>

                </v-col>
                <v-col class="col-6" v-if="profileInputType === 'edit' || profileInputType === 'new'">

                    <!-- ProfileInputCard -->
                    <profile-input-card :io_id="parseInt(ioTab - 1)" :io_data="profileInputType === 'edit' ? newScheduleObject.schedules[parseInt(ioTab - 1)].profiles[this.editedProfile] : null" @save-profile="saveProfile">
                        <template #profile_id>{{ profileInputType === 'edit' ? editedProfile + 1 : newScheduleObject.schedules[parseInt(ioTab - 1)].profiles.length + 1 }}</template>
                        <template #io_id>{{ ioTab }}</template>
                    </profile-input-card>

                </v-col>
            </v-row>
        </div>
        <confirm-dialog ref="confirmDialogue"></confirm-dialog>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SwitchProfileCard from "./SwitchProfileCard";
import ProfileInputCard from "./ProfileInputCard";
import {serializeJson} from "@/services/apiHelpers";
import {cloneDeep as _cloneDeep, reduce as _reduce, isEqual as _isEqual, isPlainObject as _isPlainObject } from "lodash";
import ConfirmDialog from "@/components/Standard/ConfirmDialog";

export default {
    name: "NewSchedule",
    components: {
        SwitchProfileCard,
        ProfileInputCard,
        ConfirmDialog
    },
    props: {
        crudType: {
            // 'new', 'edit', 'cancel', 'delete'
            type: String,
            required: false
        }
    },
    created() {
        this.populateData();
    },
    data() {
        return {
            ioTab: 1,
            newScheduleObject: {
                name: '',
                active: true,
                published: false,
                id: -1,
                uid: -1,
                schedules: [
                    {
                        io_id: 'out.1',
                        profiles: []
                    },
                    {
                        io_id: 'out.2',
                        profiles: []
                    },
                    {
                        io_id: 'out.3',
                        profiles: []
                    }
                ]
            },
            queryData: null,
            isLoaded: false,
            isValid: false,
            colors: ['#fb8c00', '#CECECE', '#BE2830'],
            category: ['aan', 'spec1', 'spec2'],
            scheduleName: '',
            changed: false,
            profileInputType: '',   // cancel, new or edit
            editedProfile: -1,
            profileTab: 1,
            mode: '',
        };
    },
    computed: {
        ...mapGetters({'highestScheduleId': 'sched/getHighestScheduleId', 'currentSchedule': 'sched/getSingleSchedule',
        'getError': 'sched/getError', 'getQueryData': 'sched/getQueryData'}),
        isValid1() {
            let valid = true;
            valid = valid && (this.scheduleName !== '');

            return valid;
        },
        hasChanged() {
            return this.changed || this.newScheduleObject.name !== this.scheduleName;
        },
        isPublished(){
            return this.newScheduleObject.published && !this.changed;
        },
        onlyNameHasChanged(){
            const nameChange = this.newScheduleObject.name !== this.scheduleName;
            return (!this.changed && nameChange)
        }
    },
    methods: {
        ...mapActions({'searchHighestScheduleId': 'sched/searchHighestScheduleId', 'searchSchedule': 'sched/searchSchedule',
            'saveSchedule': 'sched/saveSchedule'}),
        clearValues(){
            this.newScheduleObject = {
                name: '',
                active: true,
                published: false,
                version_id: -1,
                schedule_id: -1,
                schedules: [
                    {
                        io_id: 'out.1',
                        profiles: []
                    },
                    {
                        io_id: 'out.2',
                        profiles: []
                    },
                    {
                        io_id: 'out.3',
                        profiles: []
                    }
                ],
                ioState: -1,
            };
            this.scheduleName = '';
            this.active = false;
            this.published = false;
            this.changed = false;
            this.ioTab = 1;
            this.searchHighestScheduleId();
            this.newScheduleObject.schedule_id = this.highestScheduleId + 1;
            this.isValid = false;
        },
        changePublished() {
            if (!this.newScheduleObject.published) {
                if (this.currentSchedule.publish) {
                    this.mode = 'newVersion';
                } else {
                    this.mode = 'updateSchedule';
                }
            }
            this.newScheduleObject.published = !this.newScheduleObject.published;
            this.changed = true;
        },
        populateData(reload) {
            if(this.crudType === 'new' || reload === 'new') {
                this.searchHighestScheduleId();
                this.newScheduleObject.id = this.highestScheduleId + 1;
                this.scheduleName = "";
                this.isLoaded = true;
                this.editedProfile = this.newScheduleObject.schedules[parseInt(this.ioTab) - 1].profiles.length;
                const queryData = {
                    schedule_id: this.newScheduleObject.id,
                    io_id: 0,
                    profile_id: this.editedProfile,
                    crudType: 'new'
                }
                this.$store.dispatch('sched/setQueryData', queryData);
            } else if (this.crudType === 'edit' || reload === 'edit') {
                this.queryData = {
                    schedule_id: this.$route.query.schedule_id,
                    schedule_version: this.$route.query.schedule_version,
                    io_id: this.$route.query.io_id,
                    profile_id: this.$route.query.profile_id
                }
                const queryData = {
                    schedule_id: this.queryData.schedule_id,
                    schedule_version: this.queryData.schedule_version,
                    io_id: this.queryData.io_id,
                    profile_id: this.queryData.profile_id,
                    crudType: 'edit'
                }
                this.$store.dispatch('sched/setQueryData', queryData);
                this.getDataFromStore();

            } else {
                // page reload. crudType is undefined. Get query data from store.
                this.queryData = _cloneDeep(this.$store.getters['sched/getQueryData']);
                const reload2 = this.queryData.crudType;
                this.populateData(reload2)
            }
        },
        saveProfile(profileInputType, profile) {
            if (profileInputType === 'cancel'){
                this.profileInputType = 'cancel';
            } else {
                const profile2 = {
                    switches: profile.switches,
                    days: profile.days,
                    startDate: profile.startDate,
                    endDate: profile.endDate
                };
                this.ioTab = profile.io_id + 1;
                if (profileInputType === 'new') {
                    this.profileInputType = 'new';
                    profile2['profile_id'] = this.newScheduleObject.schedules[parseInt(this.ioTab) - 1].profiles.length;
                    this.newScheduleObject.schedules[parseInt(this.ioTab) - 1].profiles.push(profile2);
                    this.newScheduleObject.name = this.scheduleName;
                } else if (profileInputType === 'edit') {
                    this.profileInputType = 'edit';
                    this.getDataFromStore()
                    profile2['profile_id'] = this.editedProfile;
                    this.newScheduleObject.schedules[parseInt(this.ioTab) - 1].profiles.splice(this.editedProfile, 1, profile2);
                }
                this.saveSchedule({schedule_id : this.newScheduleObject.id, schedule: this.newScheduleObject });
                this.getDataFromStore();
                this.profileInputType = '';
                this.changed = true;
            }
        },
        async makeJsonFile() {
            // a schedule should always be 'changed' unless a schedule is published. then a new version must be made.
            // mode = 'newVersion'
            //        'newSchedule'
            //        'updateSchedule'
            //        'updateName' (to only update the name)
            if (this.crudType === 'new' || this.getQueryData.crudType === 'new') this.mode = 'newSchedule';
            else if (this.crudType === 'edit' || this.getQueryData.crudType === 'edit') {
                if (this.currentSchedule.published) {
                    // a published schedule cant be updated. Make a new Version.
                    this.mode = 'newVersion';
                    this.newScheduleObject.published = false;
                    // unless only the name has been changed.
                    if (this.onlyNameHasChanged) {
                        this.mode = 'updateName';
                    }
                } else {
                    this.mode = 'updateSchedule';
                }
            }
            this.newScheduleObject.name = this.scheduleName;
            const serializedJsonObject = serializeJson(this.newScheduleObject);

            await this.$store.dispatch('sched/sendScheduleToApi', {
                serializedScheduleObject: JSON.stringify(serializedJsonObject),
                schedule_id: this.newScheduleObject.id,
                version: this.newScheduleObject.version,
                name: this.scheduleName,                        // used when only changing the name
                mode: this.mode
            });
            let scheduleId = this.newScheduleObject.id; // The schedule_id gets overwritten during clearValues()
            let versionId = this.newScheduleObject.version; // ditto
            this.clearValues();
            if (this.crudType === 'new' || this.getQueryData.crudType === 'new') {
                const lastAddedSchedule = await this.$store.getters['sched/getLastAdded'];
                scheduleId = lastAddedSchedule.id;
                versionId = lastAddedSchedule.version;
            }
            await this.$router.push({name: 'schedInfo', query: {schedule_id: scheduleId, schedule_version: versionId}});
        },
        async getDataFromStore() {
            let schedid, versionid;
            if (this.crudType === 'edit' || this.getQueryData.crudType === 'edit') {
                schedid = parseInt(this.queryData.schedule_id);
                versionid = parseInt(this.queryData.schedule_version);
            } else {
                schedid = this.newScheduleObject.id;
                versionid = this.newScheduleObject.version;
            }

            if (this.currentSchedule.id !== schedid){

                await this.searchSchedule({schedule_id : schedid, schedule_version: versionid});

            }
            this.newScheduleObject = null;

            this.newScheduleObject = _cloneDeep(this.currentSchedule);
            this.scheduleName = this.newScheduleObject.name;



            while (this.newScheduleObject.schedules.length < 3){
                const extra_io = {
                    io_id: `io${this.newScheduleObject.schedules.length + 1}`,
                    profiles: []
                }
                this.newScheduleObject.schedules.push(extra_io);
            }

            if (this.profileInputType !== 'edit') {
                this.ioTab = this.crudType === 'edit' ? `${parseInt(this.queryData.io_id) + 1}` : `${this.ioTab}`;
            }
            this.profileTab = this.crudType === 'edit' ? `${parseInt(this.queryData.profile_id) + 1}` : this.newScheduleObject.schedules[parseInt(this.ioTab) - 1].profiles.length;
            this.isLoaded = true;
        },
        profileEdit(profile_id){
            this.profileInputType = 'edit';
            this.newScheduleObject.ioState = parseInt(this.ioTab) - 1;
            this.editedProfile = profile_id;
        },
        async profileDelete(profile_id) {
            const io = parseInt(this.ioTab) - 1;
            const ok = await this.$refs.confirmDialogue.show({
                title: this.$t('main.deleteProfile'),
                message: this.$t('main.deleteProfileMessage', { profile_id: `${profile_id + 1}`, io: `${io + 1}` }), // ${profile_id + 1} ${io}
                okButton: this.$t('main.remove'),
            });

            if (ok) {
                this.profileInputType = 'delete';
                this.newScheduleObject.schedules[parseInt(this.ioTab) - 1].profiles.splice(profile_id, 1);
                this.changed = true;
            }
            this.dialogToggle = false;
        },
        cancelChanges() {
            if (this.hasChanged) {
                this.profileInputType = 'cancel';
                this.isLoaded = true;
                this.clearValues();
                this.profileInputType = '';
                this.changed = false;
            } else {
                this.$router.go(-1);
            }
        },
        differences(obj1, obj2) {
            return _reduce(obj1, function(result, value, key) {
                if (_isPlainObject(value)) {
                    result[key] = this.differences(value, obj2[key]);
                } else if (!_isEqual(value, obj2[key])) {
                    result[key] = value;
                }
                return result;
            }, {});
        },
    },
}
</script>

<style scoped>
.errorMsg {
    color: red;
    border-color: red;
}
.errorBox {
    border: 1px solid red;
}
.header2 {
    background-color: #e3e3e3;
}
.form-header {
    background-color: #D3D3D3;
    padding: 15px 15px 15px 15px;
}
.form-control {
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 5px;
    box-shadow: #636363 5px 5px 5px;
    margin: 0 5px 0 0;
    padding: 30px 30px 30px 30px;
}
input {
    border: 1px grey solid;
    padding: 5px 10px 5px 10px;
    margin: 20px;
}
.body-control {
    max-width: 100%;
    margin: 10px;
}
.week-control {
    margin: 0 5px;
    text-align: center;
}
.week-border {
    border: 1px solid #e3e3e3;
    border-collapse: collapse;
    cursor: pointer;
}
.header-top {
    border-top: 1px solid #737373;
    padding: 2px;
}
.main-block {
    width: 1600px;
}
.inputInfoBox {
    width: 200px;
    border: red solid 1px;
    margin: 0 0 0 15px;
    padding: 2px;
}

.header-bottom {
    border-bottom: 1px solid #737373;
    padding: 2px;
}
</style>