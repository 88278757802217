<template>
    <popup-modal ref="popup">
        <h2 style="margin-top: 0">{{ title }}</h2>
        <p>{{ message }}</p>
        <div class="btns">
            <v-btn color="warning" @click="_cancel">{{ cancelButton }}</v-btn>
            <v-btn color="error" @click="_confirm">{{ okButton }}</v-btn>
        </div>
    </popup-modal>
</template>

<script>
import PopupModal from '@/components/Standard/PopupModal.vue';
export default {
    name: "ConfirmDialog",
    components: {
        PopupModal
    },
    data() {
        return {
            title: undefined,
            message: undefined,
            okButton: undefined,
            cancelButton: this.$t('main.cancel'),

            // private variables
            resolvePromise: undefined,
            rejectPromise: undefined,
        };
    },
    methods: {
        show(opts = {}) {
            this.title = opts.title;
            this.message = opts.message;
            this.okButton = opts.okButton;
            if(opts.cancelButton) {
                this.cancelButton = opts.cancelButton;
            }
            this.$refs.popup.open();
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve;
                this.rejectPromise = reject;
            });
        },
        _confirm() {
            this.$refs.popup.close();
            this.resolvePromise(true);
        },
        _cancel() {
            this.$refs.popup.close();
            this.resolvePromise(false);
        },
    }
}
</script>

<style scoped>
.btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
</style>