<template>
    <div class="pa-6 card-border">
        <div>
            <div class="d-flex flex-column">
                <div class="d-flex flex-row">
                    <div class="first-div-width"><strong>ON:</strong></div>
                    <div class="ml-2">{{ onSwitch }}</div>
                </div>
                <div class="d-flex flex-row">
                    <div class="first-div-width"><strong>OFF:</strong></div>
                    <div class="ml-2">{{ offSwitch }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SwitchDisplayCard",
    props: ['switch1'],
    methods: {
        kind(kindStr){
            const kind = ["Absolute", "Sunrise", "Sunset"]
            const retValue = this.$i18n.locale === 'en' ? kind : ["Absoluut", "Zonsopkomst", "Zonsondergang"]
            for(let i = 0; i < kind.length; i++){
                if (kindStr === kind[i]) return retValue[i];
            }
            return null;
        },
    },
    computed: {
        onSwitch(){
            let result;
            if (this.switch1.on.kind === 'Absolute') {
                result = this.switch1.on.time.substr(0,5)
            } else {
                const sign = this.switch1.on.offset < 0 ? '-' : "+";
                result = this.kind(this.switch1.on.kind) + ` ${sign} ${Math.abs(this.switch1.on.offset)}s`;
            }
            return result;
        },
        offSwitch(){
            let result;
            if (this.switch1.off.kind === 'Absolute') {
                result = this.switch1.off.time.substr(0,5)
            } else {
                const sign = this.switch1.off.offset < 0 ? '-' : '+';
                result = this.kind(this.switch1.off.kind) + ` ${sign} ${Math.abs(this.switch1.off.offset)}s`;
            }
            return result;
        },
    }
}
</script>
<style scoped>
.first-div-width {
    width: 2.2em;
}
</style>