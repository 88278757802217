/**
 * Takes an array of strings and returns a string representation of the array.
 * @param array1 the array of strings.
 * @returns {string} The string representation
 */
const arrayToString = function(array1) {
    let myString = "";
    if (!array1) return myString;
    array1.forEach((item) => {
        myString += item + ", ";
    });
    myString = myString.substring(0, myString.length - 2);
    return myString;
};

export { arrayToString };