<template>
    <div class="my-12">
        <div class="d-flex flex-row" v-if="loaded">
            <div class="mx-6">
                <v-card width="650">
                    <v-tabs
                        v-model="selectButton"
                        background-color="accent"
                        dark
                    >
                        <v-tab
                            v-for="item in selectItems"
                            :key="item.tab"
                        >{{ item.tab }}</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="selectButton">
                        <v-tab-item
                            v-for="item in selectItems"
                            :key="item.tab"
                        >
                            <v-card width="650" >
                                <assign-data-table
                                    :tableHeaders="selectButton === 0 ? nodeTableHeaders : zoneTableHeaders"
                                    :tableItems="selectButton === 0 ? nodeTableItems : zoneTableItems"
                                    @getClick="rowIsClicked">
                                    <template v-slot:tableHeader>{{ $t(tableHeader) }}</template>
                                </assign-data-table>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </div>
            <div class="mx-6">
                <schedule-profile-header :chosenSchedule="tempSchedule"/>
                <schedule-profile-card :chosenSchedule="tempSchedule" @ioProfile="getCurrentIoProfile" />
                <selected-nodes :tableType="selectButton === 0 ? 'node' : 'zone'">
                    {{ createList }}
                </selected-nodes>
                <div class="d-flex justify-space-between">
                    <div>
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <div v-on="on">
                                    <v-btn
                                        v-bind="attrs"
                                        color="info"
                                        :disabled="showButton"
                                        @click="applyScheduleToStation"
                                    >
                                        {{ $t(applySchedule) }}
                                    </v-btn>
                                </div>
                            </template>
                            <div v-if="selectButton === 0">
                                <span v-if="selectedNodes.length === 0">{{ $t('schedule.needToSelectNode') }}</span>
                                <span v-else-if="!(chosenSchedule ? chosenSchedule.published : tempSchedule.published)">{{ $t('schedule.needToPublish') }}</span>
                                <span v-else>{{ $t('tooltip.applyWarning1a') }}<br><strong>{{ $t('tooltip.applyWarning2') }}</strong></span>
                            </div>
                            <div v-else>
                                <span v-if="selectedZones.length === 0">{{ $t('schedule.needToSelectZone') }}</span>
                                <span v-else-if="!(chosenSchedule ? chosenSchedule.published : tempSchedule.published)">{{ $t('schedule.needToPublish') }}</span>
                                <span v-else>{{ $t('tooltip.applyWarning1b') }}<br><strong>{{ $t('tooltip.applyWarning2') }}</strong></span>
                            </div>
                        </v-tooltip>
                    </div>
                    <div class="d-flex">
                        <v-btn
                            @click="sendEditData"
                            color="info"
                        >
                            {{ $t('schedule.editSchedule') }}
                        </v-btn>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {
    getNodeScheduleList,
    getNodesMetaList,
    getNodesOnlineList,
    getZones
} from "@/services/apiHelpers";
import ScheduleProfileCard from "@/components/Schedule/ScheduleProfileCard";
import ScheduleProfileHeader from "@/components/Schedule/ScheduleProfileHeader";
import SelectedNodes from "@/components/Nodes/SelectedNodes";
import {cloneDeep as _cloneDeep} from "lodash";
import {mapGetters} from "vuex";
import AssignDataTable from "@/components/Nodes/AssignDataTable.vue";

export default {
    name: "AssignNodes",
    components: {
        AssignDataTable,
        ScheduleProfileHeader,
        ScheduleProfileCard,
        SelectedNodes,
    },
    props: {
        chosenSchedule: {
            type: Object,
            required: false,
            default: null
        },
        chosenNodes: {
            type: Array,
            required: false,
            default: null
        }
    },
    async mounted() {
        await this.$store.dispatch('ctz/getNodesList')
        await this.init();
    },
    data() {
        return {
            nodeList: null,
            nodeTableItems: [],
            zoneTableItems: [],
            selectedNodes: [],
            selectedZones: [],
            loaded: false,
            chosenIo: 0,
            chosenProfile: 0,
            tempSchedule: null,
            selectButton: 0,
            selectItems: [
                { tab: 'Stations', content: 'Test Stations', title: 'menu.buttons.nodes'},
                { tab: 'Zones', content: 'Test Zones', title: 'menu.buttons.zones'}
            ]
        };
    },
    computed: {
        ...mapGetters({'getSingleSchedule': 'sched/getSingleSchedule', 'activeOrganisation': 'auth/StateActiveOrganisation'}),
        applySchedule(){
            if (this.selectButton === 0){
                if(this.selectedNodes.length > 1) {
                    return 'schedule.assignScheduleToStations';
                } else return 'schedule.assignScheduleToStation';
            } else {
                if(this.selectedZones.length > 1) {
                    return 'schedule.assignScheduleToZones';
                } else
                return 'schedule.assignScheduleToZone';
            }
        },
        nodeTableHeaders() {
            return [
                {
                    id: 1,
                    text: this.$t('menu.buttons.node'),
                    value: 'id',
                },
                {
                    id: 2,
                    text: "user_field",
                    value: 'User_field',
                },
                {
                    id: 3,
                    text: this.$t('schedule.schedule'),
                    value: 'schedule.name',
                },
                {
                    id: 4,
                    text: this.$t('main.online'),
                    value: 'online',
                },
                {
                    id: 5,
                    text: 'Zone',
                    value: 'zone.name',
                },
            ];
        },
        zoneTableHeaders() {
            return [
                {
                    id: 1,
                    text: 'Name',
                    value: 'name'
                },
                {
                    id: 2,
                    text: "City",
                    value: 'city'
                },
                {
                    id: 3,
                    text: this.$t('menu.buttons.nodes'),
                    value: 'nodes'
                }
            ];
        },
        tableHeader(){
            return this.selectItems[this.selectButton].title;
        },
        tableItems(){
            return this.selectButton === 0 ? this.nodeTableItems : this.zoneTableItems;
        },
        createList() {
            let listType, returnList;
            const ltype = this.selectButton === 0 ? 'node' : 'zone';
            if (ltype === 'node'){
                listType = [...this.selectedNodes];
            } else {
                listType = [...this.selectedZones];
            }
            if (listType.length === 0) return this.$t('main.nothingSelected');
            else {
                listType.sort(this.compareNumbers);
                returnList = listType.join(", ");
            }
            return returnList;
        },
        showButton(){
            let shwBtn;
            if (this.selectButton === 0) {
                shwBtn = this.selectedNodes.length === 0;
            } else {
                shwBtn = this.selectedZones.length === 0;
            }
            return shwBtn;
        }
    },
    methods: {
        compareNumbers(a, b) {
            return a - b;
        },
        async init() {
            this.nodeList = this.$store.getters['ctz/StateNodeList'];
            if (!this.chosenSchedule) {
                const schedId = this.$route.query.schedule_id ? parseInt(this.$route.query.schedule_id) : -1;
                const versionId = this.$route.query.schedule_version ? parseInt(this.$route.query.schedule_version) : -1;
                await this.$store.dispatch('sched/searchSchedule', {schedule_id: schedId, schedule_version: versionId });
                this.tempSchedule = _cloneDeep(this.getSingleSchedule);
            } else if (this.chosenNodes){
                const schedId = this.chosenSchedule.id;
                const schedVersion = this.chosenSchedule.version;
                await this.$store.dispatch('sched/searchSchedule', {schedule_id: schedId, schedule_version: schedVersion});
                this.tempSchedule = _cloneDeep(this.getSingleSchedule);
            } else {
                this.tempSchedule = _cloneDeep(this.chosenSchedule);
            }
            await this.populateNodesList();
            await this.populateZoneList();
        },
        getCurrentIoProfile (data) {
            this.chosenIo = data.currentIo;
            this.chosenProfile = data.currentProfile;
        },
        async populateNodesList() {
            // first make the promises
            const scheduleList = getNodeScheduleList(this.nodeList);
            const onlineList = getNodesOnlineList(this.nodeList);
            const userfieldList = getNodesMetaList(this.nodeList);
            let scheduleL, onlineL, userfieldL = [];
            // then resolve all the promises at once
            await Promise.all([
                scheduleList, onlineList, userfieldList
            ])
            .then((lists) => {
                // and put them in the lists
                [scheduleL, onlineL, userfieldL] = lists;

            });
            this.nodeTableItems = [];
            scheduleL.forEach((elem) => {
                const node = {
                    id: elem.id,
                    schedule: {
                        name: elem.schedule.name ? elem.schedule.name : '',
                        schedule_id: elem.schedule.id ? elem.schedule.id : null,
                        schedule_uid: elem.schedule.uid ? elem.schedule.uid : null,
                        version: elem.schedule.version ? elem.schedule.version : null,
                    },
                    online: onlineL.find((el) => elem.id === el.id).connection.online,
                    user_field: userfieldL.find((ele) => elem.id === ele.id).user_field
                }
                this.nodeTableItems.push(node);
            });

            if(this.chosenNodes) {
                this.chosenNodes.forEach((nd) => {
                    const foundNode = this.nodeTableItems.find(el => el.id === nd);
                    this.selectedNodes.push(foundNode);
                })
            }
        },
        async populateZoneList() {
            const zonesList = _cloneDeep(await getZones(this.activeOrganisation)).zones;
            this.zoneTableItems = [];
            Promise.all(zonesList.map(async zone => getZones(this.activeOrganisation, zone.id)))
                .then((response) => response.map(zone => {
                    this.zoneTableItems.push(zone);
                    let thisZoneId = zonesList.findIndex((el) => el.id === zone.id);
                    zonesList[thisZoneId] = zone;
                }))
                .then(() => {
                    this.nodeTableItems.forEach((node1) => {
                        let zone = -1;
                        zonesList.map((el) => {
                            if (el.nodes) if (el.nodes.includes(node1.id)) {
                                node1.zone = {
                                    zone_id: el.id,
                                    name: el.name,
                                    city: el.city
                                }
                            }
                        });
                    });
                })
                .then(() => {
                    this.loaded = true
                })
                .catch((error) => console.log("There was an error : " + error));

        },
        sendEditData() {
            const scheduleObject = {
                schedule_id: this.tempSchedule.id,
                io_id: this.chosenIo,
                name: this.tempSchedule.scheduleName,
                profile_id: this.chosenProfile,
                scheduleInputType: 'edit'
            };
            this.$router.push({ name: 'schedNew',
                params: { crudType: scheduleObject.scheduleInputType },
                query: { schedule_id: `${scheduleObject.schedule_id}`, io_id: `${scheduleObject.io_id}`, profile_id: `${scheduleObject.profile_id}` }, replace: true});
        },
        async applyScheduleToStation() {
            if (this.selectButton === 0){
                // stations
                let listOfNodes = this.selectedNodes.join(",");
                const nodesList = {
                    listOfNodes: listOfNodes,
                    schedule_uid: this.tempSchedule.uid
                }
                await this.$store.dispatch('sched/sendScheduleToNodes', nodesList);
                this.selectedNodes = [];

            } else {
                // zones
                // zonesList should be list of zone-id's
                const zonesList = this.selectedZones.map((zone) => {
                    return this.zoneTableItems.find((zoneItem) => zoneItem.name === zone).id;
                });
                await this.$store.dispatch('sched/sendScheduleToZones', {uid: this.tempSchedule.uid, zonesList: zonesList })
                this.selectedZones = [];
            }
            await this.init();
        },
        rowIsClicked(list2) {
            if (this.selectButton === 0) {
                this.selectedNodes = [...list2];
            } else {
                const tempList = list2.map((elm) => this.zoneTableItems.find((zone) => zone.id === elm).name);
                this.selectedZones = [...tempList];
            }
        }
    },
}
</script>